<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4>
              <i class="ti-shopping-cart-full"> </i> 单据审批 | 配置流程
            </h4>
          </div>


        </div>
      </div>
      <div class="content">
        <el-button type="primary" @click="rowEdit()">新增流程</el-button>
        <div class="row">
          <div class="col-md-12">
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1"
              @page-changed="loadPage">
              <el-table-column prop="STEP_IDX_TYPE_NAME" label="流程类型" width="80" align="center" />
              <el-table-column prop="STEP_IDX" label="步骤号" width="80" align="center" />
              <el-table-column prop="STEP_NAME" label="步骤名称" width="150" align="center" />
              <el-table-column prop="TEA_NAME" label="处理人" width="200" align="left" />
              <!--            <el-table-column prop="COND_EXP" label="表达式" width="100" align="center"/>-->
              <el-table-column prop="COND_VAL" label="条件值" width="400" align="left" />
              <!--            <el-table-column prop="SORT" label="排序" width="60" align="center"/>-->
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)">修改</el-button>
                  <el-button type="text" icon="el-icon-edit" @click="rowDel(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="conf_edit" @on-saved="filter" />
    </div>
  </div>
</template>

<script>
import Edit from "./edit";
import Wgrid from "@/components/wgrid";
export default {
  name: "index",
  components: {Wgrid, Edit},
  data(){
    return{
      grid:{
        sea:{
          PAGE_SIZE: 12,
          PAGE_INDEX: 0
        },
        ls:[],
        total:0,
        loading:false
      }
    }
  },
  created() {
    this.getList();
  },
  methods:{
    filter(){
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi){
      this.grid.sea.PAGE_INDEX = pi-1;
      this.getList();
    },
    getList(){
      let self = this;
      this.grid.loading=true;
      this.whale.remote.getCollection({
        url: "/api/School/MAT/MVConfApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls=its;
          self.grid.total=n;
          self.grid.loading=false;
        }
      })
    },
    rowEdit(r){
      this.$refs.conf_edit.init(r);
    },
    rowDel(r) {
      this.whale.delConfirm(() => {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/MAT/MVConfApi/Delete",
          data: { ID: r.ID },
          completed: function () {
            //self.filter();
            self.grid.ls = self.grid.ls.filter(o => o.ID != r.ID)
          }
        })
      });
    },
  }
}
</script>

